var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loader,"rounded":"sm","variant":"dark","opacity":0.1}},[_c('b-card',{attrs:{"title":"Add New Part"}},[_c('validation-observer',{ref:"createPartValidator"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Category","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.categoryOptions},on:{"change":_vm.getBrands},model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Brand","label-for":"brand"}},[_c('validation-provider',{attrs:{"name":"brand","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.brandOptions,"disabled":!_vm.category_id},on:{"change":_vm.getModels},model:{value:(_vm.brand_id),callback:function ($$v) {_vm.brand_id=$$v},expression:"brand_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Model","label-for":"model"}},[_c('validation-provider',{attrs:{"name":"model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options,"disabled":!_vm.brand_id},on:{"change":_vm.getColors},model:{value:(_vm.form.mobile_model_id),callback:function ($$v) {_vm.$set(_vm.form, "mobile_model_id", $$v)},expression:"form.mobile_model_id"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Enter part name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Admin Name","label-for":"admin_name"}},[_c('validation-provider',{attrs:{"name":"admin_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"admin_name","name":"name","placeholder":"Enter part admin name"},model:{value:(_vm.form.admin_name),callback:function ($$v) {_vm.$set(_vm.form, "admin_name", $$v)},expression:"form.admin_name"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Price","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Price"},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Quantity","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Quantity"},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Required Time","label-for":"time_required"}},[_c('validation-provider',{attrs:{"name":"time_required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.timeOptions},model:{value:(_vm.form.time_required),callback:function ($$v) {_vm.$set(_vm.form, "time_required", $$v)},expression:"form.time_required"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Order Time","label-for":"order_time"}},[_c('validation-provider',{attrs:{"name":"order_time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Order time"},model:{value:(_vm.form.order_time),callback:function ($$v) {_vm.$set(_vm.form, "order_time", $$v)},expression:"form.order_time"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Priority","label-for":"priority"}},[_c('validation-provider',{attrs:{"name":"priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Priority"},model:{value:(_vm.form.priority),callback:function ($$v) {_vm.$set(_vm.form, "priority", $$v)},expression:"form.priority"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"disabled":_vm.form.mobile_model_id === null},model:{value:(_vm.form.required_color),callback:function ($$v) {_vm.$set(_vm.form, "required_color", $$v)},expression:"form.required_color"}},[_vm._v(" Required Color ")])],1)])],1),(_vm.form.required_color)?_c('b-row',_vm._l((_vm.colors),function(color,index){return _c('b-col',{key:index},[_c('div',[_c('div',[_c('b-form-group',[_c('validation-provider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-success",model:{value:(_vm.colorsRequired[index]),callback:function ($$v) {_vm.$set(_vm.colorsRequired, index, _vm._n($$v))},expression:"colorsRequired[index]"}},[_vm._v(" "+_vm._s(color.name)+" ")]),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])])}),1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Popup Information","label-for":"popup_information"}},[_c('validation-provider',{attrs:{"name":"popup_information"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-textarea',{attrs:{"id":"popup_information","name":"popup_information","placeholder":""},model:{value:(_vm.form.popup_information),callback:function ($$v) {_vm.$set(_vm.form, "popup_information", $$v)},expression:"form.popup_information"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{staticClass:"custom-control-success",on:{"change":function($event){_vm.form.upsale_description = '' && _vm.form.is_upsale}},model:{value:(_vm.form.is_upsale),callback:function ($$v) {_vm.$set(_vm.form, "is_upsale", $$v)},expression:"form.is_upsale"}},[_vm._v(" Upsale ")]),_c('b-form-checkbox',{staticClass:"custom-control-success ml-2",model:{value:(_vm.form.only_upsale),callback:function ($$v) {_vm.$set(_vm.form, "only_upsale", $$v)},expression:"form.only_upsale"}},[_vm._v(" Only Upsale ")])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Upsale Description","label-for":"upsale_description"}},[_c('validation-provider',{attrs:{"name":"upsale description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-textarea',{attrs:{"id":"upsale_description","name":"upsale_description","disabled":!_vm.form.is_upsale},model:{value:(_vm.form.upsale_description),callback:function ($$v) {_vm.$set(_vm.form, "upsale_description", $$v)},expression:"form.upsale_description"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary","type":"button"},on:{"click":_vm.clearForm}},[_vm._v(" Reset ")]),_c('b-button',{attrs:{"variant":"success","type":"submit"}},[_vm._v(" Create ")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }